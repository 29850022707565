// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// @import "node_modules/bootstrap-material-design/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
// Font-Awesome
@import "node_modules/font-awesome/scss/font-awesome";

body {
  color: #000;
  font-family: "Lato";
}

blockquote footer, blockquote small, blockquote .small {
  color: #000;
}
